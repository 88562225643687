import React, { useEffect } from "react";
import { Row, Col, Button } from "reactstrap";

// Image Imports
import ServicesImg from "../../../images/projects/services.svg";
import Web from "../../../images/services/Web.svg";
import App from "../../../images/services/App.svg";
import Design from "../../../images/services/Design.svg";
import DigCon from "../../../images/services/DigCon.svg";
import Content from "../../../images/services/Content.svg";
import Brand from "../../../images/services/Brand.svg";
import DevOps from "../../../images/services/DevOps.svg";
import Cloud from "../../../images/services/Cloud.svg";
// End of Image Imports

import ProjectHeader from "../Common/ProjectHeader";
import CallToAction from "../Common/CallToAction";

import "./Services.css";

// Services

const services = [
  {
    name: "DevOps & Network Security",
    description:
      "DevOps Security or DevSecOps is a set of practices, cultural approaches and tools that bring together software development (Dev), IT operations (Ops) and security (Sec) to increase an organization's ability to deliver applications and services at high velocity, securely. This is the future of project management practice which bridges the development and operations department with strong implementations in network security. We can set up an effective and secured project management environment to get your projects flowing without any jitter as managing a project is as tough as developing it.",
    points: [
      "Optimized Project Management",
      "Right tools for productivity",
      "Best Industry Practices",
      "Overall Process Management",
    ],
    imageURL: DevOps,
    invert: false,
  },
  {
    name: "Digital Consulting",
    description:
      "Digital Presence is the most vital aspect of your business to get leads and build a strong customer base to sustain in any market. We have the right tools and techniques to analyze your business, consult, observe and resolve your real-world problems. We will take care of all the digital aspects of your business including website, lead generation, social media, SEO and more.",
    points: [
      "Software Consulting",
      "Digital Strategy Planning",
      "Lead Generation",
      "SEO, SMO, SEM",
    ],
    imageURL: DigCon,
    invert: true,
  },
  {
    name: "Cloud Server Management",
    description:
      "Do you have a product ready but are stuck with deploying it to the server? You are just one step away from capturing your users with your web presence. We have accomplished server management services to get you going. We will establish your server and manage or troubleshoot if required. Your project deserves the best cloud, and we guarantee this",
    points: [
      "Fully Managed Cloud",
      "Best SLA",
      "Unlimited Deployments",
      "Free Set up and Upgradation",
    ],
    imageURL: Cloud,
    invert: false,
  },
  {
    name: "Branding",
    description:
      "Do you know what sets global brands like Apple and Google apart? They have seen unprecedented success because of the way they brand their products. We are here to use precise tools to build your brand with a combination of expert choices and strategy. We will design a logo, build your social media platforms, and construct a website with engaging content, optimising your way to generate solid leads and culminate a strong, established customer base.",
    points: [
      "Logo Design",
      "Digital Marketing",
      "Funnel Creation & Lead Generation",
      "Targeting the right audience",
    ],
    imageURL: Brand,
    invert: true,
  },
  {
    name: "Technical Content Writing",
    description:
      "Technology constantly evolves with time; however, the content will always remain an integral part of any organisation. We will write relevant and appealing content for your business, attracting visitors to your website and increasing their probability of becoming potential clients.",
    points: [
      "Web Content Writing",
      "Technical Writing",
      "Content Research",
      "Microblogging",
    ],
    imageURL: Content,
    invert: false,
  },
  {
    name: "Web Development",
    description:
      "Considering the evolving web technologies in the technological world, we have been continuously shaping our services to meet the growing demand for fast, secure and interactive websites. We provide complete front-end and back-end development based on the latest technologies, industry trends and as per your business requirements.",
    points: [
      "Responsive Designs Out-of-the-box",
      "Best-in-class UI/UX",
      "MEAN & MERN Stack Projects",
      "Search Engine Optimization",
    ],
    imageURL: Web,
    invert: true,
  },
  {
    name: "Mobile App Development",
    description:
      "We use the latest technologies and tools to develop world-class, quality mobile apps for your business. This will help you manage and run your business and allow access to crucial business information. Minimise your costs and maximise your productivity.",
    points: ["Android Apps", "iOS Apps", "React Native Apps", "Hybrid Apps"],
    imageURL: App,
    invert: false,
  },
  {
    name: "Web & Graphic Design",
    description:
      "We have an excellent and experienced graphic design team that can breathe life into your ideas. Our team will assist with getting the right colours, graphics, and layout to ensure an appealing graphic design to attract your audience. Your dreams are just a click away!",
    points: [
      "All-in-one Business Solution",
      "Modern State-of-the-Art Designs",
      "Posters & Brochures",
      "Highly Flexible and Customizable Designs",
    ],
    imageURL: Design,
    invert: true,
  },
];

function Services(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title =
      "Services | 300Media - Web and Mobile Applications Development";
  }, []);
  return (
    <>
      <ProjectHeader title="Services" img={ServicesImg} />
      <section className="services-section">
        <div className="container">
          <h3 className="mb-3">What We Offer</h3>
          <h6>
            We offer various services to suit your business needs at affordable
            and flexible costs. We are your one-stop solution to start branding
            your dream project to reach your target audience within a few
            months. We have many fantastic services, ranging from your project’s
            micro-management to bespoke marketing, providing you with everything
            you need to establish your brand.
          </h6>
          <h6>
            We focus on web and mobile application development, which are
            crucial tools you need to drive traffic to your site as a business
            owner. Attracting customers is an art form, and our team of skilled,
            highly trained professionals can get the job done for you. Your
            leads are just a click away!
          </h6>
          <h6>
            Digital transformation has changed the approach to reaching the
            right audience, with great tools available in the market. 300 Media
            will help you leverage the right processes to track activity and
            ensure your business remains in people’s thoughts. We believe in
            your business goals and will help you reach the right set of people
            to increase your conversion rates dramatically.
          </h6>
          <h6>
            Are you just starting? We have the whole package. Relax and leave
            the rest to us!
          </h6>
        </div>
        <div className="services">
          {services.map((service) => {
            return (
              <div className="service-item">
                <Row>
                  {service.invert !== true ? (
                    <>
                      <Col
                        sm="12"
                        md="5"
                        lg="5"
                        className="d-flex justify-content-center"
                      >
                        <img src={service.imageURL} alt={services.imageURL} />
                      </Col>
                      <Col sm="12" md="7" lg="7">
                        <h3>{service.name}</h3>
                        <p>{service.description}</p>
                        <ul className="tag-group">
                          {service.points.map((point) => {
                            return (
                              <li>
                                <span className="highlight-u">{point}</span>
                              </li>
                            );
                          })}
                        </ul>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col sm="12" md="7" lg="7">
                        <h3>{service.name}</h3>
                        <p>{service.description}</p>
                        <ul className="tag-group">
                          {service.points.map((point) => {
                            return (
                              <li>
                                <span className="highlight-u">{point}</span>
                              </li>
                            );
                          })}
                        </ul>
                      </Col>
                      <Col
                        sm="12"
                        md="5"
                        lg="5"
                        className="d-flex justify-content-center"
                      >
                        <img src={service.imageURL} alt={services.imageURL} />
                      </Col>
                    </>
                  )}
                </Row>
              </div>
            );
          })}
        </div>
      </section>
      <CallToAction />
    </>
  );
}

export default Services;
