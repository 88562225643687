import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";

// Common Components
import Header from "../src/components/pages/Common/Header";
import Footer from "../src/components/pages/Common/Footer";
import Preloader from "../src/components/pages/Common/Preloader";

// Pages
import Home from "../src/components/pages/Home/Home";
import AboutUs from "../src/components/pages/About/AboutUs";
import Contact from "../src/components/pages/Contact/Contact";
import Projects from "../src/components/pages/Projects/Projects";
import Services from "../src/components/pages/Services/Services";
import VideoEmbed from "../src/components/pages/Common/VideoEmbed";
import Careers from "../src/components/pages/Careers/Careers";
import Terms from "../src/components/pages/Legal/Terms";
import Privacy from "../src/components/pages/Legal/Privacy";
import GDPR from "../src/components/pages/Legal/GDPR";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Toaster
          position="bottom-center"
          reverseOrder={false}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            // Define default options

            // Default options for specific types
            loading: {
              duration: 2000,
              theme: {
                primary: "green",
                secondary: "black",
              },
              style: {
                background: "#363636",
                color: "#fff",
                width: "100%",
              },
            },
            success: {
              duration: 3000,
              theme: {
                primary: "green",
                secondary: "black",
              },
              style: {
                background: "var(--dark-2)",
                color: "var(--white)",
                width: "100%",
              },
            },
            error: {
              duration: 3000,
              theme: {
                primary: "green",
                secondary: "black",
              },
              style: {
                background: "#363636",
                color: "#fff",
                width: "100%",
              },
            },
          }}
        />
        <Preloader />
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<AboutUs />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/projects" element={<Projects />} />
          <Route exact path="/services" element={<Services />} />
          <Route
            exact
            path="/video/giej8742ax1bsug"
            element={<VideoEmbed />}
          />
          {/* <Route exact path="/careers" element={<Careers />} /> */}
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/GDPR" element={<GDPR />} />

          {/* 404 not found */}
          {/* <Route path="*" component={Home} /> */}
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
